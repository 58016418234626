#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
#text {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  height: 200px;
  width: 200px;
  text-align: center;
}
