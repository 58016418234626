#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;

  }
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #035779;
    color: white;
    text-align: center;
    position: sticky;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 14px;
  }
  .table {
    position: absolute;
}
  
  /* #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;} */
  
