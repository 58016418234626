.header {
  /* height:15.5vh; */
  height: 145px;
  /* background-color: rgb(42 39 92); */
  background-color: rgb(255, 255, 255);
  /* background: linear-gradient(
    146deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 170, 238, 1) 54%
  ); */
  -webkit-box-shadow: -1px 12px 26px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 12px 26px -6px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 12px 26px -6px rgba(0, 0, 0, 0.75);
  z-index: 999;
}
.help{
    height:50px;
    background-color: blueviolet;
}
.text-styles {
  font-size: 25px;
  padding-left: 20px;
  font-weight: 600;
  color: #d7af4c;
  text-align: center;
}
.second{
    height: 20px;
    background-color: aqua;
}
h1{
    text-align: center;
    color:  #9d56a2;;
}
.flex{
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    justify-content: space-between;
    background-color: #fff;
}
.secondflex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding-top: 11px; */
    /* background-color: rgb(68, 146, 254); */
    background-color: #00aaee;
    align-items: center;
    /* height: 58px ; */
    margin-top: 4px;
}
@media screen and (max-width: 980px) {

    h1{
        font-size: 1rem;
    }
    .secondflex{
        margin-top: 49px;
    }
}
@media screen and (max-width: 1705px) {

    .secondflex{
        /* margin-top: 5px; */
    }
}
