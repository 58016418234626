.flex{
    display: flex;
    flex-direction: row;
}
.border{
    border-top: 1px solid gray;
    margin-top: 40px;
}
.fibre{
    min-height: 222px;
    /* background-color: #f0fbff; */
    margin: 10px;
    border-radius: 5px;
    /* box-shadow: 0 0 3px; */
    border: 1px solid #bfe5f3;
}
h3{
    /* text-align: center; */
    font-family:'Roboto';
    /* padding-top: 10px; */
    font-size: 17px;
    margin: 0;
    padding-top: 4px;
    padding-left: 26px;
    padding-bottom: 5px;
    background-color: #6eb8d3;
    color:#035779
    
}
.row{
    display: flex;
    margin-left: 30px;
}
.left{
    width: 60rem;
}
.right{
    width:60rem;
}
.icon_holder{
    width: 28px;
    background-color: #d9d9d9;
    border-radius: 4px;
    

}
.icon_holder:hover{
    background-color: #acacac;
}
.source{
    display: flex;
    justify-content: space-around;
}
h4{
    color: #035779;
    font-size: 20px;
    margin: 10px;
    
}
h2{
    margin: 0;
    padding: 0;
    font-size: 1.3rem;
    color: #127299;
}
.css-6hp17o-MuiList-root-MuiMenu-list{
    max-height: 400px;
}