.edit:hover{
    background-color: rgb(37, 91, 209);
    font-size: 20px;
    transition: all .5s;
    color: rgb(0, 106, 255);

}
/* Button:hover{
    background-color: aqua;
} */
.edit2{
    background-color: rgb(97, 160, 247);
    border: 1px solid rgb(57 97 160);
    border-radius: 6px;
    /* height: 32px; */
    cursor: pointer;
    font-size: 17px;
    color: aliceblue;

}
.delete{
    background-color: rgb(247, 97, 97);
    border: 1px solid rgb(160, 57, 57);
    border-radius: 6px;
    height: 32px;
    cursor: pointer;
    font-size: 17px;
    color: aliceblue;
    margin-left: 7px;
}
.delete:hover{
    background-color: rgb(209, 37, 37);
    font-size: 20px;
    transition: all .5s;
    color: rgb(0, 106, 255);
}
.show{
    background-color: rgb(96, 211, 43);
    border: 1px solid rgb(74, 160, 57);
    border-radius: 6px;
    height: 32px;
    cursor: pointer;
    font-size: 17px;
    color: aliceblue;
    margin-left: 7px;
}
.show:hover{
    background-color: rgb(74, 176, 6);
    font-size: 20px;
    transition: all .5s;
    color: rgb(0, 106, 255);
}
.icon{
    font-size: 17px;
}
/* span{
    color: rgb(255, 255, 255);
    margin-bottom: 5px;
    position: relative;
    top: -7px;
} */
.level{
    color: rgb(255, 255, 255);
    margin-bottom: 5px;
    position: relative;
    top: -7px;
}
@media screen and (max-width: 1649px) {
    .show{
        margin-left: 0px;
    }
}