/* .edit:hover{
    background-color: rgb(37, 91, 209);
    font-size: 20px;
    width: 75px;
    transition: all .5s;
    color: rgb(0, 106, 255);

}
.edit{
    background-color: rgb(97, 160, 247);
    border: 1px solid rgb(57 97 160);
    border-radius: 6px;
    height: 32px;
    cursor: pointer;
    font-size: 17px;
    width: 70px;
    color: aliceblue;
} */
.delete{
    background-color: rgb(247, 97, 97);
    border: 1px solid rgb(160, 57, 57);
    border-radius: 6px;
    height: 32px;
    cursor: pointer;
    font-size: 17px;
    color: aliceblue;
    margin-left: 7px;
}
.delete:hover{
    background-color: rgb(209, 37, 37);
    font-size: 20px;
    transition: all .5s;
    color: rgb(0, 106, 255);
}
.showfmslocation{
    background-color: rgb(96, 211, 43);
    border: 1px solid rgb(74, 160, 57);
    border-radius: 6px;
    height: 32px;
    cursor: pointer;
    font-size: 17px;
    color: aliceblue;
    margin-left: 7px;
}
.showfmslocation:hover{
    background-color: rgb(74, 176, 6);
    font-size: 20px;
    transition: all .5s;
    color: rgb(0, 106, 255);
}
@media screen and (max-width: 1485px) {
    .showfmslocation{
        margin-left: 0px;
    }
}