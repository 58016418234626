.container {
  background-color: rgb(43 51 62);
  height: 100%;
  overflow: auto;
  box-shadow: 0 0 50px #ccc;
}
::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #55bfe6;
  border-radius: 10px;
}
.submenu{
    background-color: rgb(43 51 62);
    width: 4px;
    height: 35px;
}
.divider{
    /* border-top: 1px solid rgb(0 170 238);
    border-bottom: 1px solid rgb(0 170 238); */
    border-top: 1px solid rgb(157 86 162);
    border-bottom: 1px solid rgb(157 86 162);
    
}
.dividertop{
    border-top: 1px solid rgb(157 86 162);
}