.footer {
  height: 50px;
  background-color: rgb(43 51 62 / 94%)
}
.text-style {
  padding-left: 20px;
  /* color: #d7af4c; */
  color: #4492fe;
  font-size: 12px;
}
span{
    font-size: 16px;
}
b{
    font-size: 18px;
}